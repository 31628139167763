<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="moveToList()" class="back">
        <Back />
      </a>
      <strong class="lo-title">신규 AS 접수</strong>
    </div>
    <div class="lo-subitem">
      동일 내용의 다량 문의 접수 건은 1건 접수 처리되며, 동일 내용 및 중복 접수건은 삭제될 수
      있습니다.
    </div>
    <div class="lo-row" v-if="sil">
      <div class="lo-btnRowBox">
        <button class="lo-Btn" @click="silSet()">평면도 {{ silType }}</button>
        <button class="lo-Btn" v-show="type == 'PRE'" @click="jitemSet()" v-if="sil.notice">
          점검 제외 품목 {{ jitemType }}
        </button>
      </div>
      <div v-if="sil.notice && jitemOpen" v-html="sil.notice">
      </div>

      <h2 style="white-space:nowrap;" class="mt-2 mb-2" v-if="silOpen">
        {{ sil.type }}
      </h2>
      <v-carousel class="incellStyle" height="auto" hide-delimiters hide-delimiter-background
        :show-arrows="sil.images.length > 1" v-if="silOpen">
        <v-carousel-item v-for="image in sil.images" :key="`sil-image-` + image" style="max-width:100%;">
          <img :src="image" style="max-width:100%;max-height:100%;margin:auto;" />
        </v-carousel-item>
      </v-carousel>
    </div>

    <v-toolbar flat color="white" style="border-radius:10px;">
      <v-toolbar-title style="width:100%">
        <v-row> 진행률 ({{ progress }} / 7) </v-row>
        <v-row>
          <v-col cols="12">
            <v-progress-linear :value="ratio"
              :color="ratio == 100 ? 'success accent-4' : 'primary accent-4'"></v-progress-linear>
          </v-col>
        </v-row>
      </v-toolbar-title>
    </v-toolbar>

    <div class="parent-container" id="option-grid">
      <div class="child-container">
        <v-card flat class="mt-2 card" id="option-1">
          <v-card-text>
            <h2 class="text-h6 mb-2">
              1. "실"을 선택하세요.
            </h2>
            <v-chip-group v-model="item[1]" column @change="
        moveTo(2);
      setPart();
      ">
              <v-chip filter outlined small v-for="option in areaOptions" :key="option" :value="option"
                :color="option == item[1] ? 'primary' : ''">
                {{ option }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
        <v-slide-y-transition>
          <v-card flat class="mt-2 card" v-if="item[1]" id="option-2">
            <v-card-text>
              <h2 class="text-h6 mb-2">
                2. "부위"를 선택하세요.
              </h2>

              <v-chip-group v-model="item[2]" column @change="
        moveTo(3);
      setDetail();
      ">
                <v-chip filter outlined small v-for="option in partOptions" :key="option" :value="option"
                  :color="option == item[2] ? 'primary' : ''">
                  {{ option }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <v-card flat class="mt-2 card" v-if="item[2]" id="option-3">
            <v-card-text>
              <h2 class="text-h6 mb-2">
                3. "상세부위"를 선택하세요.
              </h2>

              <v-chip-group v-model="item[3]" column>
                <v-chip filter outlined small v-for="option in detailOptions" :key="option.id" :value="option"
                  :color="option == item[3] ? 'primary' : ''" @change="
        moveTo(4);
      setCause(option);
      ">
                  {{ option.partDetailName }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <v-card flat class="mt-2 card" v-if="item[3] && causeOptions.length" id="option-4">
            <v-card-text>
              <h2 class="text-h6 mb-2">
                4. "AS원인"을 선택하세요.
              </h2>

              <v-chip-group v-model="item[4]" column @change="moveTo(5)" return-object>
                <v-chip filter outlined small v-for="option in causeOptions" :key="option.id" :value="option"
                  :color="option.id == item[4].id ? 'primary' : ''" @click="item[4] = option">
                  {{ option.name }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <v-card flat class="mt-2 card" v-if="item[4]" id="option-5">
            <v-card-text>
              <h2 class="text-h6 mb-2">
                5. AS에 대한 설명을 입력하세요.
              </h2>

              <v-textarea outlined v-model="item[5]" hint="10자 이상 입력하여야 합니다." persistent-hint counter></v-textarea>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
        <v-slide-y-transition>
          <v-card flat class="mt-2 card" v-if="item[5].length >= 10">
            <v-card-text>
              <h2 class="text-h6 mb-2">
                6.AS부위 사진을 등록하세요.
              </h2>
              <v-file-input @click="moveTo('8')" @change="chooseFile($event, 6)" truncate-length="15" accept="image/*"
                label="근거리 사진 (약 30cm 이하)" show-size @click:clear="item[6] = null"></v-file-input>
              <v-file-input @change="chooseFile($event, 7)" truncate-length="15" accept="image/*"
                label="원거리 사진 (약 1M 이내)" show-size @click="moveTo('8')" @click:clear="item[7] = null"></v-file-input>
              <div style="text-align: left;font-weight:bold;color:#f36628;">
                10MB 이하의 파일만 업로드 가능합니다.
              </div>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
        <div id="option-index"></div>
        <div id="option-7"></div>
        <v-row>
          <v-col cols="3">
            <v-btn color="grey" block large elevation="0" dark class="mt-2" @click="clear(0)">
              초기화
            </v-btn>
          </v-col>
          <v-col class="ml-2" @click="progressWarn(ratio != 100)">
            <v-btn block large elevation="0" class="mt-2" color="primary" @click="openConfirm()"
              :disabled="ratio != 100">
              AS 접수</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block large elevation="0" color="white" @click="moveToList()">
              목록으로
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-dialog v-model="openDialog" max-width="400" scrollable>
        <v-card class="content">
          <v-card-title class="justify-center">
            AS 접수 하시겠습니까?
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list subheader two-line>
              <v-list-item v-if="siteDefectItem">
                <v-list-item-content>
                  <v-list-item-title>부위 및 원인</v-list-item-title>
                  <span filter small color="primary" readonly class="font-weight-bold mt-2 primary--text">
                    {{ siteDefectItem.areaName }}
                    <v-icon small> mdi-chevron-right </v-icon>
                    {{ siteDefectItem.partName }}
                    <v-icon small> mdi-chevron-right </v-icon>
                    {{ siteDefectItem.partDetailName }}
                    <v-icon small> mdi-chevron-right </v-icon>
                    {{ item[4].name }}
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>설 명</v-list-item-title>
                  <div class="black--text mt-2 ml-2" v-html="item[5]"></div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>첨부사진</v-list-item-title>
                  <v-list-item-subtitle class="mt-2">
                    <v-img class="card" :src="img_1"></v-img>
                    <v-img class="mt-2 card" :src="img_2"></v-img>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" dark color="grey" @click="openDialog = false">취 소</v-btn>
            <v-btn elevation="0" color="primary" @click="postItem()" :disabled="loadingBar">접 수</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center" v-if="loadingBar">
      <v-progress-circular fullscreen indeterminate color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import * as defect from "@/api/defect";
import Weblogo from "@/assets/images/common/new-logo.svg";
import Back from "@/assets/images/icon/icon-back.svg";
import heic2any from "heic2any";

export default {
  name: "DefectAdd",
  components: {
    Back,
    Weblogo,
  },
  data() {
    return {
      isCreated: false,
      type: "AFTER",
      areaOptions: [],
      partOptions: [],
      detailOptions: [],
      causeOptions: [],
      item: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: null,
        7: null,
      },
      openDialog: false,
      siteDefectItem: {},
      img_1: null,
      img_2: null,
      loadingBar: true,
      silType: "닫기",
      silOpen: true,
      jitemType: "보기",
      jitemOpen: false,

      // 평면도
      sil: null,
    };
  },
  computed: {
    hideDefect() {
      const now = new Date()

      let defectAddClosed = false;
      if (this.type == "PRE" && this.home.defect_pre_end_at) {
        defectAddClosed = new Date(this.home.defect_pre_end_at) < now
      } else if (this.type != "PRE" && this.home.defect_after_end_at) {
        defectAddClosed = new Date(this.home.defect_after_end_at) < now
      }

      let defectMenuOpen = false

      if (this.type == "PRE") {
        defectMenuOpen = this.home.is_defect_pre_open
      } else {
        defectMenuOpen = this.home.is_defect_after_open
      }

      return defectAddClosed || !defectMenuOpen;
    },
    progress() {
      let val = 0;
      for (let i = 1; i < 8; i++) {
        if (i == 5) {
          if (this.item[i].length >= 10) {
            val += 1;
          }
        } else if (this.item[i]) {
          val += 1;
        }
      }
      return val;
    },
    ratio() {
      if (this.progress == 7) {
        return 100;
      }
      return this.progress * (100 / 7);
    },
    home() {
      if (this.type == "AFTER") {
        return this.$store.getters.getTenantHome || this.$store.getters.getHome;
      } else {
        return this.$store.getters.getHome;
      }
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    silSet() {
      this.silOpen = !this.silOpen;
      this.silOpen ? (this.silType = "닫기") : (this.silType = "열기");
    },
    jitemSet() {
      this.jitemOpen = !this.jitemOpen;
      this.jitemOpen ? (this.jitemType = "닫기") : (this.jitemType = "보기");
    },
    // APIs
    getOptions() {
      this.loadingBar = true;
      defect
        .getSiteDefectItems({
          siteCode: this.home.erp_village_id,
          size: 100000,
        })
        .then((res) => {
          this.options = res.data.content;
          this.areaOptions = res.data.areas;
        })
        .finally(() => {
          this.loadingBar = false;
        });
    },

    getCauseByPartDetailAPI(id) {
      this.loadingBar = true;
      defect
        .getCauseByPartDetail(id)
        .then((res) => {
          this.causeOptions = res.data.map((r) => r.cause);
        })
        .finally(() => {
          this.loadingBar = false;
        });
    },
    postItem() {
      if (this.isCreated) {
        return;
      }
      this.loadingBar = true;
      let data = {
        type: this.type,
        customer: {
          name: this.user.name,
          uniqueNumber: this.user.mobile,
        },
        ho: {
          name: this.home.ho_no,

          dong: {
            name: this.home.dong_no,
            site: {
              code: this.home.erp_village_id,
              name: this.home.erp_village_name,
            },
          },
        },
        siteDefectItem: {
          id: this.siteDefectItem.id,
        },
        defectStatus: "REQUESTED",
        cause: this.item[4],
        requirement: this.item[5],
        receiptAt: this.getNowFullString(true),
      };

      // 분리형세대인 경우에 고객지원시스템 ho name 규칙에 맞춰서 보내줌 !!
      if (this.home.erp_no_room && this.home.erp_no_room != "0000") {
        data.ho.name = data.ho.name + "-" + this.home.erp_no_room;
      }

      if (!this.item[6] || !this.item[7]) {
        this.$store.commit("ERROR", "사진을 등록해주세요.");
        return;
      }
      this.isCreated = true;
      defect
        .postDefect(data)
        .then((res) => {
          this.postFilesAPI(res.data.id, [this.item[6], this.item[7]]);
        })
        .catch((err) => {
          this.isCreated = false;
          if (err.response.status == 400) {
            // 현재 날짜와 점검 기간 구분 후, 맞지 않은 기간이면 BAD_REQUEST
            this.$store.commit("ERROR", "접수 기간이 아닙니다.");
          } else {
            this.$store.commit("ERROR", "하자 등록에 실패하였습니다.");
          }
        });
    },
    postFilesAPI(id, files) {
      if (files.length != 2 || !files[0] || !files[1]) {
        // 하자사진 2장이 첨부되지 않았을경우
        this.deleteBugDefect(id)
        return;
      }

      let idx = 0;
      files.forEach((file) => {
        let data = {
          file: file,
          defectId: id,
          defectFileType: "BEFORE",
        };

        defect
          .postDefectFile(data)
          .then(() => {
            idx += 1;
            if (idx == files.length) {
              this.moveToDetail(id);
              this.loadingBar = false;
            }
          })
          .catch((err) => {
            this.deleteBugDefect(id)
            this.loadingBar = false;
          })
      });
    },

    loadSil(id) {
      defect
        .getSil(id)
        .then((res) => {
          this.sil = res.data;
        })
        .catch((e) => {
          this.sil = null;
        });
    },
    // Utils
    setPart() {
      this.partOptions = [];
      this.options.forEach((option) => {
        if (option.areaName == this.item[1] && !this.partOptions.includes(option.partName)) {
          this.partOptions.push(option.partName);
        }
      });
    },
    setDetail() {
      this.detailOptions = [];
      this.options.forEach((option) => {
        if (
          option.areaName == this.item[1] &&
          option.partName == this.item[2] &&
          !this.detailOptions.includes(option)
        ) {
          this.detailOptions.push(option);
        }
      });
    },
    setCause(selected) {
      this.siteDefectItem = this.options.find(
        (option) =>
          option.areaName == this.item[1] &&
          option.partName == this.item[2] &&
          option.sitePartDetailMap.partDetailId == selected.sitePartDetailMap.partDetailId
      );

      this.getCauseByPartDetailAPI(selected.sitePartDetailMap.partDetailId);
    },
    openConfirm() {
      this.img_1 = URL.createObjectURL(this.item[6]);
      this.img_2 = URL.createObjectURL(this.item[7]);

      this.openDialog = true;
    },

    // Utils
    clear(val) {
      for (let i = 1; i < 8; i++) {
        if (i > val) {
          this.item[i] = "";
          if (i >= 6) {
            this.item[i] = null; // 파일 초기화값 null
          }
        }
      }
    },

    moveTo(val) {
      this.clear(val - 1);
      if (val > 2) {
        var myElement = document.getElementById(`option-${val - 1}`);
        var topPos = myElement.offsetTop - 200;
        document.getElementById("option-grid").scrollTop = topPos;
      }
    },

    /** return now full string ex) 20220101 */
    getNowFullString(dash = false) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hours = String(today.getHours()).padStart(2, "0");
      var minues = String(today.getMinutes()).padStart(2, "0");
      var seconds = String(today.getSeconds()).padStart(2, "0");

      if (dash) {
        return `${yyyy}-${mm}-${dd}T${hours}:${minues}:${seconds}`;
      } else {
        return `${yyyy}${mm}${dd}${hours}${minues}`;
      }
    },
    moveToList() {
      if (this.$route.name == "DefectPreAdd") {
        this.$router.push({
          name: "DefectPreList",
          query: { type: this.type },
        });
      } else {
        this.$router.push({ name: "DefectList", query: { type: this.type } });
      }
    },
    moveToDetail(id) {
      this.checkBugImageDefect(id).then(res => {
        if (this.$route.name == "DefectPreAdd") {
          this.$router.push({
            name: "DefectPreDetail",
            params: { id: id },
            query: { type: this.type },
          });
        } else {
          this.$router.push({
            name: "DefectDetail",
            params: { id: id },
            query: { type: this.type },
          });
        }
      }).catch(e => {
        this.deleteBugDefect(id)
      });

    },
    chooseFile(e, i) {
      // 파일 사이즈를 줄인다.
      if (!e) {
        return;
      } else if (e.type.toUpperCase().includes("HEIC")) {
        // heic 파일일 경우 변환
        try {
          this.convertHeicImage(e).then((res) => {
            this.item[i] = res;
          }).catch(error =>{
            if (error.code == 1){
              // 변환이 필요없는 파일의 경우 확장자 명만 변경
              const name = e.name.replace(".heic", ".jpeg").replace(".HEIC", ".jpeg")
              var blob = e.slice(0, e.size, 'image/jpeg'); 
              const newFile = new File([blob], name, {type: 'image/jpeg'});
              this.item[i] = newFile
            }else{
              this.$store.commit("ERROR", "사진 업로드에 실패하였습니다. 파일 양식을 확인하세요 (001)")
            }
          })
        } catch (error) {
          console.log(error, 2);
          this.$store.commit("ERROR", "사진 업로드에 실패하였습니다. 파일 양식을 확인하세요 (002)");
        }
        return;
      }

      try{
        this.$funcs.getDownSizeFile(e).then((res) => {
          this.item[i] = res;
        }).catch((error) =>{
          console.log(error, 2)
          this.item[i] = e
        })
      } catch (error){
          console.log(error, 3);
          this.$store.commit("ERROR", "사진 업로드에 실패하였습니다. 파일 양식을 확인하세요 (003)");
      }
    },

    convertHeicImage(file) {
      return new Promise((resolve, reject) => {
        heic2any({ blob: file, toType: "image/jpeg", quality: 0.5 })
          .then((conversionResult) => {
            resolve(conversionResult);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    deleteBugDefect(id) {
      alert("하자 등록에 실패하였습니다. 첨부된 파일 양식이 올바르지 않습니다. 다시 시도해 주세요.");
      defect.deleteDefect(id).then(() => {
        window.location.reload();
      });
    },
    checkBugImageDefect(id) {
      // 특수한 상황으로 사진이 2장 모두 등록되지 않은경우
      return new Promise((resolve, reject) => {
        defect.getDefect(id).then((res) => {
          let beforeFiles = res.data.files.filter((file) => file.fileType == "BEFORE");
          if (beforeFiles.length < 2) {
            reject(false);
          } else {
            resolve(true);
          }
        });
      });

    },
    progressWarn(disabled){
      if (!disabled) return;
      const warnMap={
        1:'1."실"을 선택하세요',
        2:'2."부위"을 선택하세요',
        3:'3."상세부위"을 선택하세요',
        4:'4."AS원인"을 선택하세요',
        5:'5.AS에 대한 설명을 입력하세요.',
        6:'6.AS부위 근거리 사진을 등록하세요.',
        7:'7.AS부위 원거리 사진을 등록하세요.'
      }

      let warn = ""
      for (let i = 1; i < 8; i++){
        if (!this.item[i]){
          warn = warnMap[i]
          break
        }
      }

      if (warn) this.$store.commit("ERROR", warn);
    }
  },
  created() {

      // 상가 접수 제한 S 
      if (this.home && ["S"].includes(this.home.erp_village_type)){
        this.$store.commit("ERROR", "상가는 AS 접수가 불가능합니다.");
        this.$router.push({ name: "contract" });
        return;
      }

    this.type = this.$route.query.type || "AFTER";

    if (this.hideDefect) {
      let typeText = this.type == "PRE" ? "사전점검" : "하자";
      this.$store.commit("ERROR", `${typeText} AS 접수기간이 아닙니다.`);
      this.$router.push({ name: "contract" });
    } else {
      if (this.type == "AFTER") {
        if (!this.home || !this.home.is_defect_after_open) {
          this.$router.push({ name: "dwelling" });
        }
      } else {
        if (!this.home || !this.home.is_defect_pre_open) {
          this.$router.push({ name: "contract" });
        }
      }

      this.loadSil(this.home?.id);

      this.getOptions();
      // this.postItem(); // WARN: remove this
    }
  },
};
</script>

<style scoped>
.lo-btnRowBox {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lo-btnRowBox button {
  display: inline-flex;
  flex: 1 1 50%;
  margin-right: 5px;
  letter-spacing: -0.75px;
}

.lo-btnRowBox button:last-of-type {
  margin-right: 0;
}

.incellStyle {
  max-width: 400px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}

.parent-container {
  height: calc(100vh - 270px);
  overflow-y: scroll;
}

.child-container {
  min-height: 700px;
}

.card {
  border-radius: 10px;
}

.content {
  text-align: left;
}

@media (max-width: 760px) {
  .v-chip {
    flex: 0 1 31%;
    height: 36px;
    line-height: 1.2;
    padding: 0 6px;
    justify-content: center;
    white-space: inherit;
    word-break: break-all;
    overflow: visible;
    margin: 0 0.5% 1%;
    font-size: 11px;

    .v-icon {
      font-size: 14px !important;
      margin-left: 0 !important;
      margin-right: 5px !important;
    }
  }
}
</style>
