<template>
  <v-container class="bg-gray vh-full ps-re" fill-height fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col><IntroTitle /></v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="mw-400 bAl-center">
            <a click="moveTo">
              <IntroIllust />
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          rounded
          min-width="280"
          min-height="50"
          elevation="0"
          color="rgba(230,106,31,1)"
          class="white--text text-h6"
          @click="moveTo"
          >시작하기</v-btn
        >
        <!--<div class="csm-link" v-if="is_app">현장 매니저/작업자 분들은 <a @click="movetoCSM()">여기</a>를 클릭하세요</div>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IntroTitle from "@/assets/svgs/intro_title.svg";
import IntroIllust from "@/assets/svgs/intro_illust.svg";
export default {
  name: "IntroPage",
  components: { IntroTitle, IntroIllust },
  data() {
    return {
      link: "http://lynn.co.kr/",
      csmview:false,
    };
  },
  computed: {
    browserInfo() {
      return navigator.userAgent.toLowerCase();
    },
    userState() {
      return this.$store.getters.getUserState;
    },
    is_android() {
      return this.browserInfo.indexOf("android") > 0;
    },
    is_ios() {
      return (
        this.browserInfo.indexOf("iphone") > 0 ||
        this.browserInfo.indexOf("ipad") > 0
      );
    },
    is_app() {
      return this.browserInfo.indexOf("wikey_flutter") > 0;
    },
  },
  methods: {
    moveTo() {
      /*
        1. 'NONMEMBER' - 비휘원
        2. 'MEMBER' - 회원
        3. 'CONTRACTOR' - 계약자
        4. 'TENANT' - 입주자
      */
      if (["NONMEMBER", "MEMBER"].includes(this.userState)) {
        this.$store.state.switchMode = false;
        this.$router.push({ name: "villageInfoList" });
      } else if ("CONTRACTOR" == this.userState) {
        this.$store.state.switchMode = true;
        this.$router.push({ name: "contract" });
      } else if ("TENANT" == this.userState) {
        this.$store.state.switchMode = true;
        this.$router.push({ name: "dwelling" });
      }
    },
    appUpdateCheck() {},
    movetoCSM() {
      window.flutter_inappwebview
        .callHandler("gotoCSM")
        .then(function(result) {
          this.loadingBar = false;
          console.log("@@@ flutterInAppWebViewPlatformReady gotoCSM  result: ", result);
        }
      );
    },
  },
  created() {
    this.$store.dispatch("USER_REFRESH", true);

    // 임대허브 url로 유입된 경우 임대허브 사이트로 이동한다.
    var url = location.href;
    var rentUrl = ["stay.lynn.co.kr", "hub.smartlynn.co.kr"];

    var hubCheck = false;
    // url이 rentUrl에 있는 문자열들을 포함하고 있는지 체크
    for (var i = 0; i < rentUrl.length; i++) {
      if (url.indexOf(rentUrl[i]) > 0) {
        hubCheck = true;
      }
    }

    if (hubCheck) {
      // 임대 허브로 이동
      this.$store.state.hub = true;
      this.$router.push({ name: "hub" });
    } else {
      // 일반 스마트린
      this.$store.state.hub = false;

      // 로그인된 경우, intro화면 거치지 않고 바로 메인 화면으로 간다.
      if (this.$store.state.auth.isLoggedIn) {
        console.log(this.userState);
        if ("TENANT" == this.userState) {
          this.$store.state.switchMode = true;
          this.$router.replace({ name: "dwelling" });
        } else if ("CONTRACTOR" == this.userState) {
          this.$store.state.switchMode = true;
          this.$router.replace({ name: "contract" });
        }
      }

      setTimeout(function() {
        // 앱으로 접속한 경우, 앱 버전을 체크하고 업데이트 알림
        let browserInfo = navigator.userAgent.toLowerCase();
        let is_android = browserInfo.indexOf("android") > 0;
        let is_ios =
          browserInfo.indexOf("iphone") > 0 || browserInfo.indexOf("ipad") > 0;
        if (browserInfo.indexOf("wikey_flutter") > 0) {
          //TODO: 안드로이드와 ios의 버전이 다름
          try {
            window.flutter_inappwebview
              .callHandler("getAppInfo")
              .then(function(result) {
                var json = JSON.parse(result);
                var version = json.app_version;
                if (is_android && version != "1.2.9") {
                  let check = confirm(
                    "새 버전이 있습니다.\n새 버전을 설치하시겠습니까?"
                  );
                  if (check)
                    window.location.replace(
                      "market://details?id=com.woomi.lynnlife"
                    );
                }
                // else if(is_ios && version != "1.2.3"){
                //   let check = alert("새 버전이 있습니다.\n새 버전을 설치하시겠습니까?");
                //   if(check) window.location.replace("https://apps.apple.com/app/id1559671877");
                // }
              });
          } catch (e) {
            console.log(e);
          }
        }
      }, 1000);
    }
  },
};
</script>
