<template>
  <v-container class="pad nb web-contract" v-show="facilityList.length">
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="educationList.length"
    >
      교육/육아
    </strong>
    <ul class="list-box">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in educationList"
        :key="'Education-card-' + idx"
      />
    </ul>
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="sharingList.length"
    >
      공유/편의
    </strong>
    <ul class="list-box">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in sharingList"
        :key="'Sharing-card-' + idx"
      />
    </ul>
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="healthList.length"
    >
      건강
    </strong>
    <ul class="list-box">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in healthList"
        :key="'Health-card-' + idx"
      />
    </ul>
    <strong
      class="ds-b fts16 ftw4 lh20 text-left color-6 mb15"
      v-if="etcList.length"
    >
      기타
    </strong>
    <ul class="list-box">
      <FacilityCard
        :item="item"
        v-for="(item, idx) in etcList"
        :key="'ETC-card-' + idx"
      />
    </ul>
  </v-container>
</template>

<script>
/*icons*/

import { getFacilityList } from "@/api/dwelling";
import FacilityCard from "@/components/Dwelling/FacilityCard.vue";

export default {
  components: {
    FacilityCard,
  },
  name: "FacilityGrid",
  data() {
    return {};
  },
  computed: {
    facilityList() {
      return this.$store.getters.getFacilityList;
    },
    educationList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_EDUCATION";
      });
    },
    sharingList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_SHARING";
      });
    },
    healthList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_HEALTH";
      });
    },
    etcList() {
      return this.facilityList.filter((i) => {
        return i.facility.category_code == "FACILITY_CATEGORY_ETC";
      });
    },
  },
  methods: {
    getFacilityListAPI() {
      getFacilityList("is_simple=Y").then((res) => {
        this.$store.commit("SET_FACILITY_LIST", res.data);
      });
    },
  },
  created() {
    this.getFacilityListAPI();
  },
};
</script>
