export default {
  state: {
    facilityList: [],
  },
  getters: {
    getFacilityList(state) {
      return state.facilityList;
    },
  },
  mutations: {
    SET_FACILITY_LIST(state, data) {
      state.facilityList = data;
    },
  },
};
