// CSCenter: 고객지원
import csCenter from "@/views/CsCenterViews/CsCenter.vue";
import faq from "@/views/CsCenterViews/Faq.vue";
import faqIn from "@/views/CsCenterViews/FaqIn.vue";
import notice from "@/views/CsCenterViews/Notice.vue";
import noticeVillage from "@/views/CsCenterViews/NoticeVillage.vue";
import noticeIn from "@/views/CsCenterViews/NoticeIn.vue";
import noticeInVillage from "@/views/CsCenterViews/NoticeInVillage.vue";
import qna from "@/views/CsCenterViews/Qna.vue";
import privatePolicy from "@/views/CsCenterViews/Private.vue";

var csCenterRoutes = [
  // 고객지원
  {
    path: "/csCenter",
    name: "csCenter",
    component: csCenter,
    meta: {
      header: {
        title: "고객지원",
      },
      bottomNav: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: faq,
    meta: {
      bottomNav: true,
    },
  },
  {
    path: "/faqIn/:id",
    name: "faqIn",
    component: faqIn,
    meta: {
      bottomNav: true,
    },
  },
  {
    path: "/notice",
    name: "notice",
    component: notice,
    meta: {
      bottomNav: true,
    },
  },
  {
    path: "/noticevillage",
    name: "noticeVillage",
    component: noticeVillage,
    meta: {
      bottomNav: true,
      requiresAuth: true,
    },
  },
  {
    path: "/noticeIn/:id",
    name: "noticeIn",
    component: noticeIn,
    meta: {
      bottomNav: true,
    },
  },
  {
    path: "/noticeInVillage/:id",
    name: "noticeInVillage",
    component: noticeInVillage,
    meta: {
      bottomNav: true,
      requiresAuth: true,
    },
  },
  {
    path: "/qna",
    name: "qna",
    component: qna,
    meta: {
      bottomNav: true,
    },
  },
  {
    path: "/privatePolicy",
    name: "privatePolicy",
    component: privatePolicy,
    meta: {
      bottomNav: true,
    },
  },
];

export { csCenterRoutes };
