<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web-in bg-gray" fluid>
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30"/>
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">AS 접수 목록</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text style="padding-top:0px; margin-top:0px;">
        <v-row>
          <v-col>
            <v-chip-group v-model="status" mandatory>
              <v-chip small value="ALL" outlined :color="colorByStatus('ALL')">
                전 체
              </v-chip>
              <v-chip small value="REQUESTED" outlined :color="colorByStatus('REQUESTED')">
                <v-icon small>mdi-timer-sand-empty</v-icon>
                접수중
              </v-chip>
              <v-chip small value="OPEN" outlined :color="colorByStatus('OPEN')">
                <v-icon small>mdi-account-hard-hat-outline</v-icon>
                AS처리중
              </v-chip>
              <v-chip small value="CLOSE" outlined :color="colorByStatus('CLOSE')">
                <v-icon small>mdi-check</v-icon>
                AS처리완료
              </v-chip>
              <v-chip small value="CANCELLED" outlined :color="colorByStatus('CANCELLED')">
                <v-icon small>mdi-cancel</v-icon>
                AS미대상
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn v-if="!isXs && !hideDefect" text color="primary" @click="moveToAdd()">
                <v-icon>mdi-plus-circle-outline</v-icon>
                신규 AS 접수
              </v-btn>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-card v-for="(item, index) in filteredItems" :key="index" flat ripple class="item-card"
          @click="moveToDetail(item.id)">
          <span class="order-style"> {{item.order}}</span>
          <v-list>
            <v-list-item :key="item.title">
              <v-list-item-avatar>
                <v-icon color="primary" v-if="item.defectStatus == 'OPEN' && item.visitAt && type != 'PRE'">
                  mdi-account-hard-hat-outline
                </v-icon>
                <v-icon color="success" v-else-if="item.defectStatus == 'REQUESTED'">
                  mdi-timer-sand-empty
                </v-icon>
                <v-icon color="primary" v-else-if="item.defectStatus == 'OPEN'">
                  mdi-timer-sand-empty
                </v-icon>
                <v-icon color="red" v-else-if="item.defectStatus == 'CANCELLED'">
                  mdi-cancel
                </v-icon>
                <v-icon color="grey" v-else>
                  mdi-check
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="item.title" style="font-weight:500;"></v-list-item-title>
                <v-list-item-subtitle class="mt-1" v-html="item.createdAtText"></v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.defectStatus == 'OPEN' && item.visitAt && type != 'PRE'"
                  class="font-weight-bold primary--text" v-html="item.visitAtText"></v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.defectStatus == 'REQUESTED'"
                  class="font-weight-bold success--text" v-html="'접수중'"></v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.defectStatus == 'OPEN'" class="font-weight-bold primary--text"
                  v-html="'AS 처리중'"></v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.defectStatus == 'CANCELLED'" class="font-weight-bold red--text"
                  v-html="'AS 미대상'"></v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.defectStatus == 'CLOSE'"
                  class="font-weight-bold grey--text text--darken-2" v-html="'AS 처리완료'"></v-list-item-subtitle>
                <v-list-item-subtitle v-else v-html="item.receiptAtText"></v-list-item-subtitle>
                <!-- <v-list-item-subtitle
                  v-show="item.defectStatus == 'CLOSE'"
                  v-html="item.visitAtText"
                ></v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <li class="no-list mt-5" v-if="isLoading">
          <p>로딩 중 입니다..</p>
        </li>
        <li class="no-list mt-5" v-else-if="!items.length">
          <p>접수된 AS가 없습니다.</p>
        </li>
        <li class="no-list mt-5" v-else-if="!filteredItems.length">
          <p>해당되는 상태의 AS가 없습니다.</p>
        </li>
      </v-card-text>
    </v-card>
    <!-- <v-btn block text @click="loadMore" color="success" v-show="showLoadMore"><v-icon>mdi-plus-circle </v-icon>모든 내역
      조회</v-btn> -->
    <v-btn color="primary" fab dark bottom right fixed class="float-add-button" v-if="isXs && !hideDefect"
      @click="moveToAdd()">
      <v-icon large>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import * as defect from "@/api/defect";
import { get_now_string, get_time_string } from "@/utils/time"

export default {
  name: "DefectList",
  components: { Back, Weblogo },
  data() {
    return {
      type: "AFTER",
      show: true,
      cancelDialog: false,
      page: 1,
      status: "ALL",
      isLoading: true,
      items: [],
      isSimple: true,
      showLoadMore: false,
      scrollY:0
    };
  },
  computed: {
    hideDefect() {
      const now = new Date()

      let defectAddClosed = false;
      if (this.type == "PRE" && this.home.defect_pre_end_at) {
        defectAddClosed = new Date(this.home.defect_pre_end_at) < now
      } else if (this.type != "PRE" && this.home.defect_after_end_at) {
        defectAddClosed = new Date(this.home.defect_after_end_at) < now
      }

      let defectMenuOpen = false

      if (this.type == "PRE") {
        defectMenuOpen = this.home.is_defect_pre_open
      } else {
        defectMenuOpen = this.home.is_defect_after_open
      }

      // 입주자 접수 

      return defectAddClosed || !defectMenuOpen;
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    filteredItems() {
      if (this.status == "ALL") {
        return this.items;
      }
      return this.items.filter((item) => item.defectStatus == this.status);
    },
    user() {
      return this.$store.getters.getUser;
    },
    home() {
      if (this.type == "AFTER") {
        return this.$store.getters.getTenantHome || this.$store.getters.getHome;
      } else {
        return this.$store.getters.getHome;
      }
    },
  },
  methods: {
    // APIs
    getItems() {
      let params = {
        sort: "createdAt,desc",
        customerUniqueNumber: this.user?.mobile.replaceAll("-", ""),
        // size: this.isSimple ? 10 : 10000,
        size: 10000,
      };

      if (this.type == "PRE") {
        params.type = "PRE";
        params.dongName = this.home.dong_no;
        params.hoName = this.home.ho_no;
        params.siteCode = this.home.erp_village_id;
      } else {
        params.typeIn = "SECOND_YEAR,THIRD_YEAR,TEN_YEAR,AFTER,MOVE_IN";
      }

      defect
        .getDefects(params)
        .then((res) => {
          if (res.data) {
            this.items = res.data.content.map((item, index) => this.parse(item, index, res.data.totalElements));
            if (res.data.totalElements > 10 && this.isSimple) {
              this.showLoadMore = true;
            }
          } else {
            this.items = [];
          }
          
        })
        .finally(() => {
          if (this.scrollY){
            window.scrollTo(0, this.scrollY)
          }
          
          this.isLoading = false;
        });
    },
    parse(item, index, total) {
      item.order = `00${total - index}`.slice(-3);
      item.createdAtText = `등 록: ${this.timeForm(item.createdAt)}`;
      item.receiptAtText = `접 수: ${this.timeForm(item.receiptAt)}`;
      item.visitAtText = `방 문: ${this.timeForm(item.visitAt)}`;
      // item.completeAtText = `방 문: ${this.timeForm(item.completeAt)}`;
      if (item.defectStatus === "WORK_DONE") {
        item.defectStatus = "OPEN";
      }

      // if (item.defectStatus === "REQUESTED") {
      //   item.defectStatus = "OPEN";
      // }

      item.title = `${item.siteDefectItem.areaName} > ${item.siteDefectItem.partName} > ${item.siteDefectItem.partDetailName}`;
      return item;
    },
    loadMore() {
      this.showLoadMore = false;
      this.isSimple = false;
      this.getItems();
    },
    // Utils
    timeForm(text) {
      if (!text) return "";
      return text.replace("T", " ").slice(0, 16);
    },
    colorByStatus(val) {
      if (val != this.status) return "grey darken-2";
      return (
        {
          ALL: "primary",
          CANCELLED: "red",
          REQUESTED: "success",
          OPEN: "primary",
          WORK_ORDERED: "success",
          CLOSE: "grey ",
        }[this.status] || "primary"
      );
    },
    moveToAdd() {
      if (this.$route.name == "DefectPreList") {
        this.$router.push({ name: "DefectPreAdd", query: { type: this.type } });
      } else {
        this.$router.push({ name: "DefectAdd", query: { type: this.type } });
      }
    },
    moveToDetail(id) {
      if (this.$route.name == "DefectPreList") {
        this.$router.push({
          name: "DefectPreDetail",
          params: { id: id },
          query: { type: this.type, scrollY:window.scrollY },
        });
      } else {
        this.$router.push({ name: "DefectDetail", params: { id: id }, query: { type: this.type, scrollY:window.scrollY } });
      }
    },
  },
  created() {
    this.type = this.$route.query.type || "AFTER";
    if (this.$route.query.scrollY){
      this.scrollY = Number(this.$route.query.scrollY || 0);
    }else{
      try{
        this.scrollY = this.$previousRoute.split("scrollY=")[1]
      } catch{
        this.scrollY = 0
      }
    }
    // this.scrollY 초기화
    this.$router.replace({ query: {type:this.type} });

    if (this.type == "AFTER") {
      if (!this.home || !this.home.is_defect_after_open) {
        this.$router.push({ name: "dwelling" });
      }
    } else {
      if (!this.home || !this.home.is_defect_pre_open) {
        this.$router.push({ name: "contract" });
      }
    }

    this.getItems();
  },
};
</script>

<style scoped>
.item-card {
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.float-add-button {
  margin-bottom: 50px;
}

.v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 4px 0;
}

.order-style{
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: grey
}
</style>
